import React from "react"
import { graphql } from "gatsby"

import Layout from "src/components/Layout"
import SEO from "src/components/Seo"
import Hero from "src/components/FeaturesHero"
import Details from "src/components/FeatureResourcePlanning"

// Query for the Blog Post content in Prismic
export const query = graphql`
  query BlogPostQuery($uid: String) {
    prismic {
      allFeaturess(uid: $uid) {
        edges {
          node {
            title
            items {
              itemTitle
              itemImage
              itemDescription
            }
            image
            description
          }
        }
      }
    }
  }
`

const ResourcePlanning = props => {
  try {
    const data = props.data.prismic.allFeaturess.edges[0].node
    return (
      <Layout>
        <SEO title={data.title[0].text} />

        <Hero
          title={data.title[0].text}
          description={data.description[0].text}
          image={data.image}
          isInvoicing={data.title[0].text === "Invoicing"}
        />

        <Details items={data.items} />
      </Layout>
    )
  } catch (err) {
    return null
  }
}

export default ResourcePlanning
