import React from 'react'
import propType from 'prop-types'
import cx from 'clsx'
import Fade from 'react-reveal/Fade'

import s from './featureResourcePlanning.module.scss'

const FeatureResourcePlanning = ({ items }) => {

  return (
    <div className={s.wrapper}>
      <div className="container">
        {items.map((item, index) => {
          const { itemTitle, itemDescription, itemImage } = item

          return (
            <div key={index} className={cx('row', s.row, { [s.oddRow]: index % 2 !== 0 })}>
              <div className="col-lg-6">
                <Fade bottom cascade>
                  <div className={s.img}>
                    <img src={itemImage.url} alt={itemImage.alt} />
                  </div>
                </Fade>
              </div>

              <div className="col-lg-6">
                <Fade bottom cascade>
                  <div className={s.content}>
                    <h2 className={s.title}>{itemTitle[0].text}</h2>
                    <p className={s.description}>{itemDescription[0].text}</p>
                  </div>
                </Fade>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

FeatureResourcePlanning.propTypes = {
  items: propType.arrayOf(
    propType.shape({
      itemTitle: propType.array,
      itemDescription: propType.array,
      itemImage: propType.object
    })
  )
}


export default FeatureResourcePlanning
