import React from 'react'
import cx from 'clsx'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { FeaturesHeroWave } from 'src/components/Svg'

import s from './featuresHero.module.scss'

const FeaturesHero = ({ title, description, image, isInvoicing }) => (
  <div className={cx(s.heroSection, { [s.isInvoicing]: isInvoicing })}>
    <Fade top cascade>
      <FeaturesHeroWave className={s.heroIcon} />
    </Fade>

    <div className={s.wrapper}>
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-lg-4">
            <Fade left cascade>
              <div className={s.left}>
                <h1 className={s.title}>{title}</h1>
                <p className={s.description}>{description}</p>
              </div>
            </Fade>
          </div>
          <div className="col-md-7 col-lg-8">
            <Fade right cascade>
              <img src={image.url} className={s.bisFlowMainImage} alt={image.alt} />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  </div>
)

FeaturesHero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  isInvoicing: PropTypes.bool
}

export default FeaturesHero
